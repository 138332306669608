<template>
  <ValidationProvider :name="$t(sLabel)" :rules="sRules" :vid="sVid" slim>
    <template #default="{ errors, valid }">
      <v-select
        v-model="sDocType"
        :error-messages="errors"
        :success="required ? valid : undefined"
        :items="items"
        v-bind="obAttrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import Utils, { CountryCode, IDList, IDListKey } from "@/services/Utils";
import { LocationModule } from "@/store/location";
import { Component, Mixins, Prop, VModel } from "vue-property-decorator";
import { camelCase, map } from "lodash";
import SelectFieldMixin from "@/mixins/SelectFieldMixin";

@Component
export default class SelectDocType extends Mixins(SelectFieldMixin) {
  @VModel({ type: String }) sDocType!: string;

  @Prop(Boolean) readonly taxExempt!: boolean;
  @Prop([String, Number]) readonly countryId!: number;

  // arItems: Record<string, any>[] = [];
  sLabelDefault: string = "doctype";
  obIDList: Partial<IDList> = {};

  async mounted() {
    this.obIDList = await Utils.getIDList();
  }

  get items() {
    const arKeys = this.countryCode
      ? Utils.validateIDByCountry(this.countryCode)
      : [];

    return map(this.obIDList, (sValue, sKey: IDListKey) => {
      return {
        text: sValue,
        value: sKey,
        disabled:
          (arKeys.length && !arKeys.includes(sKey)) ||
          (this.taxExempt && ["ci", "rut"].includes(sKey)),
      };
    });
  }

  get sRules() {
    return this.required ? "required" : "";
  }

  get sVid() {
    return camelCase(this.label);
  }

  get countryCode(): CountryCode | null {
    if (!this.countryId) {
      return null;
    }

    const obCountry = this.countries.find({ id: this.countryId });
    return obCountry ? obCountry.get("code") : null;
  }

  get countries() {
    return LocationModule.countries;
  }

  onSelect(sValue: string) {
    this.$emit("input", sValue);
    this.$emit("change", sValue);
  }
}
</script>
